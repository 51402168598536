import HeaderNavigation from "@/components/common/Layout/header/HeaderNavigation"
import styled from "styled-components"
import Footer from "@/components/common/Layout/footer/Footer"
import { Button } from "@mui/material"
import { MdHeartBroken } from "react-icons/md"
import { useRouter } from "next/router"
import translate from "@/utils/translation"
import { useEffect } from "react"

function NotFound({ languages }) {
  const router = useRouter()

  const title = "404 Not Found"

  return (
    <>
      <Wrapper>
        <title>{title}</title>
        <HeaderNavigation />
        <div className="container">
          <div className="row">
            <div className="col-12">
              <div className="text-center mt-5">
                <MdHeartBroken size={94} color="grey" />
                <h4 className="mt-4">{translate("404_title")}</h4>
                <Button
                  variant="contained"
                  color="primary"
                  onClick={() => {
                    router.push("/")
                  }}
                  className="mt-4 rounded-pill"
                >
                  {translate("404_button")}
                </Button>
              </div>
            </div>
          </div>
        </div>
      </Wrapper>
      <Footer />
    </>
  )
}

export default NotFound

const Wrapper = styled.div`
  width: 100%;
  min-height: 90vh;
`
